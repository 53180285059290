import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";

export default class extends Controller {
  static targets = [
    "form",
    "title",
    "status",
    "dueDate",
    "assignedUsers",
    "userSearchResults",
    "availableUsersData",
    "avatarTemplate",
  ];

  static values = {
    workInteractionId: String,
    workStreamId: String,
  };

  connect() {
    this.formTarget.addEventListener(
      "turbo:submit-end",
      this.submitEnd.bind(this),
    );

    // Initialize the controller
    this.allUsers = [];
    this.selectedUsers = new Set();
    this.loadAvailableUsers();

    // Initialize any existing selected users
    const assignedUsersSelect =
      this.assignedUsersTarget.querySelector("select");
    if (assignedUsersSelect) {
      for (let i = 0; i < assignedUsersSelect.options.length; i += 1) {
        const option = assignedUsersSelect.options[i];
        if (option.selected) {
          this.selectedUsers.add(option.value);
        }
      }
    }

    // Populate the user search results initially
    this.populateAvailableUsers();
  }

  submitEnd(e) {
    if (e.detail.success) {
      // Track action creation event
      trackEventWithDataToAmplitudeAndGoogle(
        "create",
        "action",
        "Action Added",
        {
          work_interaction_id: this.workInteractionIdValue,
          work_stream_id: this.workStreamIdValue,
        },
      );

      // if the form was successful, close the modal defined by the modalTarget or the closest modal
      const modal = this.hasModalTarget
        ? $(this.modalTarget)
        : $(this.formTarget.closest(`.modal`));
      modal.modal("hide");
    }
  }

  loadAvailableUsers() {
    // Load available users from the hidden data div
    const userDataElements =
      this.availableUsersDataTarget.querySelectorAll(".user-data");
    this.allUsers = Array.from(userDataElements).map((userElement) => ({
      id: userElement.dataset.userId,
      fullName: userElement.dataset.fullName,
      email: userElement.dataset.email,
      avatarUrl: userElement.dataset.avatarUrl,
    }));
  }

  filterUsers(event) {
    this.populateAvailableUsers(event.target.value);
  }

  populateAvailableUsers(searchTerm = "") {
    // Clear the current results
    this.userSearchResultsTarget.innerHTML = "";

    // Filter users based on search term
    const filteredUsers = this.allUsers.filter((user) => {
      if (!searchTerm) return true;

      const searchTermLower = searchTerm.toLowerCase();
      const fullNameLower = (user.fullName || "").toLowerCase();
      const emailLower = (user.email || "").toLowerCase();

      return (
        fullNameLower.includes(searchTermLower) ||
        emailLower.includes(searchTermLower)
      );
    });

    // Sort users by name
    const sortedUsers = filteredUsers.sort((a, b) => {
      // Handle null or undefined fullName values
      if (!a.fullName) return 1; // Move null values to the end
      if (!b.fullName) return -1; // Move null values to the end
      return a.fullName.localeCompare(b.fullName);
    });

    // Create and append user items
    sortedUsers.forEach((user) => {
      const userItem = document.createElement("div");
      userItem.className =
        "user-item d-flex align-items-center p-2 border-bottom";

      const isSelected = this.selectedUsers.has(user.id);

      userItem.innerHTML = `
        <div class="form-check">
          <input class="form-check-input" 
                 type="checkbox" 
                 id="user-${user.id}" 
                 value="${user.id}" 
                 ${isSelected ? "checked" : ""}
                 data-action="change->actions--action-add#toggleUserSelection">
        </div>
        <label class="ms-2 d-flex align-items-center" for="user-${user.id}">
          <div class="avatar-xs me-2">
            <img src="${user.avatarUrl}"
                 width="24"
                 height="24"
                 class="rounded-circle"
                 alt="${user.fullName}">
          </div>
          <div>
            <div class="fw-bold">${user.fullName || "Unnamed User"}</div>
            <div class="text-muted small">${user.email || ""}</div>
          </div>
        </label>
      `;

      this.userSearchResultsTarget.appendChild(userItem);
    });

    // If no users found, show a message
    if (sortedUsers.length === 0) {
      const noResults = document.createElement("div");
      noResults.className = "p-3 text-center text-muted";
      noResults.textContent =
        this.userSearchResultsTarget.dataset.emptyMessage || "No users found";
      this.userSearchResultsTarget.appendChild(noResults);
    }
  }

  toggleUserSelection(event) {
    const checkbox = event.target;
    const userId = checkbox.value;

    if (checkbox.checked) {
      this.selectedUsers.add(userId);
    } else {
      this.selectedUsers.delete(userId);
    }

    // Update the select element to match selected users
    this.updateAssignedUsersSelect();
    // Update the avatar display
    this.updateAvatarDisplay();
  }

  updateAssignedUsersSelect() {
    const assignedUsersSelect =
      this.assignedUsersTarget.querySelector("select");
    if (assignedUsersSelect) {
      for (let i = 0; i < assignedUsersSelect.options.length; i += 1) {
        const option = assignedUsersSelect.options[i];
        option.selected = this.selectedUsers.has(option.value);
      }
    }
  }

  updateAvatarDisplay() {
    // Find the avatar group container
    const avatarGroup = this.element.querySelector(".avatar-group");
    if (!avatarGroup) return;

    // Remove all existing avatars
    avatarGroup.innerHTML = "";

    // Get all selected users from our allUsers array
    const selectedUsers = this.allUsers
      .filter((user) => this.selectedUsers.has(user.id))
      .sort((a, b) => {
        // Handle null or undefined fullName values
        if (!a.fullName) return 1; // Move null values to the end
        if (!b.fullName) return -1; // Move null values to the end
        return a.fullName.localeCompare(b.fullName);
      });

    // Add avatars to the group
    selectedUsers.forEach((user) => {
      // Clone the avatar template
      const template = this.avatarTemplateTarget.content.cloneNode(true);
      const avatarItem = template.querySelector(".avatar-group-item");

      // Set the user data in the cloned template
      const img = avatarItem.querySelector("img");
      img.src = user.avatarUrl;
      img.title = user.fullName;
      img.alt = user.fullName;

      avatarGroup.appendChild(avatarItem);
    });

    // If no users selected, show a message
    if (selectedUsers.length === 0) {
      const noUsers = document.createElement("div");
      noUsers.className = "text-muted small";
      noUsers.textContent = "No users assigned";
      avatarGroup.appendChild(noUsers);
    }
  }

  saveNewAction() {
    // Submit the action-add-form form
    const form = this.formTarget;
    if (form && form.id === "action-add-form") {
      form.requestSubmit();
    }
  }
}
