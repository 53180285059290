import $ from "jquery";
import { Controller } from "@hotwired/stimulus";

/**
 * Handles a form loaded in a turbo frame, inside a modal dialog. Makes sure to close the dialog
 * if the form is successfull.
 * If it's not, the dialog should stay open, as the form has errors that need to me visible to the user.
 */
export default class extends Controller {
  static targets = ["form", "modal"];

  connect() {
    this.formTarget.addEventListener(
      "turbo:submit-end",
      this.submitEnd.bind(this),
    );
  }

  submitEnd(e) {
    if (e.detail.success) {
      // if the form was successful, close the modal defined by the modalTarget or the closest modal
      const modal = this.hasModalTarget
        ? $(this.modalTarget)
        : $(this.formTarget.closest(`.modal`));
      modal.modal("hide");
      // reload the page to show the updated content unless specified otherwise
      if (this.formTarget.dataset.reloadPage !== "false") {
        window.location.reload();
      }
    }
  }
}
