/* eslint import/prefer-default-export: "off" */
/**
 * Speech Recognition Behavior
 * Provides common speech recognition functionality using AssemblyAI
 * for use across different controllers.
 */

import AssemblyAISpeech from "../speech_recognition/assembly_ai_speech";
import { trackEventWithDataToAmplitudeAndGoogle } from "../analytics/global_analytics";

export const useSpeechRecognition = (controller) => {
  // Create speech recognition instance
  const speechRecognition = new AssemblyAISpeech();

  // Assign all properties and methods to the controller
  Object.assign(controller, {
    // State
    speechRecognition,
    isRecording: false,

    // Methods
    toggleSpeechRecognition() {
      if (this.isRecording) {
        this.stopSpeechRecognition();
      } else {
        this.startSpeechRecognition();
      }
    },

    async startSpeechRecognition() {
      try {
        // Update button appearance
        this.micButtonTarget.classList.remove("btn-outline-secondary");
        this.micButtonTarget.classList.add("btn-mic-recording");

        // Start recording
        await this.speechRecognition.startRecording((isRecording) => {
          this.isRecording = isRecording;
        });

        // Track event
        trackEventWithDataToAmplitudeAndGoogle(
          "SpeechRecognition",
          "chatbot",
          "Work Stream Chatbot - Speech Recognition Started",
          {},
        );
      } catch (error) {
        this.micButtonTarget.classList.remove("btn-mic-recording");
        this.micButtonTarget.classList.add("btn-outline-secondary");
      }
    },

    async stopSpeechRecognition() {
      if (!this.speechRecognition || !this.isRecording) return;

      try {
        // Update button appearance while processing
        this.micButtonTarget.classList.remove("btn-mic-recording");
        this.micButtonTarget.classList.add("btn-mic-processing");

        // Show spinner icon and hide mic icon
        this.micButtonTarget.querySelector(".mic-icon").classList.add("d-none");
        this.micButtonTarget
          .querySelector(".spinner-icon")
          .classList.remove("d-none");

        // Stop recording and get transcription
        await this.speechRecognition.stopRecording(
          (result) => {
            if (result) {
              // Set the transcribed text to the input field
              this.textQuestionTarget.value = result;
              this.textQuestionTarget.focus();

              // Track successful transcription
              trackEventWithDataToAmplitudeAndGoogle(
                "SpeechRecognition",
                "chatbot",
                "Work Stream Chatbot - Speech Recognition Completed",
                { length: result.length },
              );
            }
          },
          (isRecording) => {
            this.isRecording = isRecording;
          },
        );

        // Reset button appearance
        this.micButtonTarget.classList.remove("btn-mic-processing");
        this.micButtonTarget.classList.add("btn-outline-secondary");

        // Show mic icon and hide spinner icon
        this.micButtonTarget
          .querySelector(".mic-icon")
          .classList.remove("d-none");
        this.micButtonTarget
          .querySelector(".spinner-icon")
          .classList.add("d-none");
      } catch (error) {
        this.micButtonTarget.classList.remove("btn-mic-recording");
        this.micButtonTarget.classList.remove("btn-mic-processing");
        this.micButtonTarget.classList.add("btn-outline-secondary");

        // Show mic icon and hide spinner icon
        this.micButtonTarget
          .querySelector(".mic-icon")
          .classList.remove("d-none");
        this.micButtonTarget
          .querySelector(".spinner-icon")
          .classList.add("d-none");
      }
    },
  });

  return controller;
};
