import { Controller } from "@hotwired/stimulus";
import { useDropdown, useSidePanelContent, useDialog } from "../../behaviors";

export default class WorkInteractionUpdateFormController extends Controller {
  static targets = [
    "work_streamsFieldElement",
    "work_streamHelpText",
    "submitButton",
    "dialogWrapper",
    "dialogOverlay",
  ];

  static classes = ["errorFieldWrapper", "errorHelpText", "hidden"];

  static helpTextHiddenClass = "hidden";

  connect() {
    useDropdown(this, this.work_streamsFieldElementTarget);
    useSidePanelContent(this);
    useDialog(this);
  }

  clickOutside(evt) {
    this.openDialog(evt);
  }

  handleSelectValue_changed() {
    this.toggleWorkStreamHelpMessage();

    if (this.work_streamsFieldElementTarget.value) {
      const selectedOption =
        this.work_streamsFieldElementTarget.options[
          this.work_streamsFieldElementTarget.selectedIndex
        ];

      const dropdownButton = this.work_streamsFieldElementTarget
        .closest(".dropdown")
        .querySelector(".dropdown-toggle");
      if (dropdownButton) {
        dropdownButton.textContent = selectedOption.text;
      }
    }
  }

  toggleWorkStreamHelpMessage() {
    if (this.work_streamsFieldElementTarget.value) {
      this.work_streamHelpTextTarget.classList.add(
        WorkInteractionUpdateFormController.helpTextHiddenClass,
      );
    } else {
      this.work_streamHelpTextTarget.classList.remove(
        WorkInteractionUpdateFormController.helpTextHiddenClass,
      );
    }
  }

  shouldBypassDialog() {
    const selectedSelectValue = this.work_streamsFieldElementTarget.value;
    const originalSelectedValue =
      this.work_streamsFieldElementTarget.dataset.initialValue;

    const bypassDialog = selectedSelectValue === originalSelectedValue;
    return bypassDialog;
  }
}
