import { Controller } from "@hotwired/stimulus";
import { useDialog } from "../../behaviors";

export default class WorkInteractionDetailAssignWorkStreamFormController extends Controller {
  static targets = [
    "formElement",
    "work_streamsFieldElement",
    "work_streamsFieldWrapper",
    "work_streamsErrorList",
    "submitButton",
  ];

  static classes = ["errorFieldWrapper", "hidden", "errorHelpText"];

  connect() {
    useDialog(this);
    this.setupForm();
  }

  setupForm() {
    // Store initial value for comparison
    if (this.hasWork_streamsFieldElementTarget) {
      this.work_streamsFieldElementTarget.dataset.initialValue =
        this.work_streamsFieldElementTarget.value;
    }
  }

  handleSelectValue_changed(event) {
    // Clear any existing error messages
    if (this.hasWork_streamsErrorListTarget) {
      this.work_streamsErrorListTarget.innerHTML = "";
    }

    if (this.hasWork_streamsFieldWrapperTarget) {
      this.work_streamsFieldWrapperTarget.classList.remove(
        this.errorFieldWrapperClass,
      );
    }

    // Show submit button when a value is selected
    if (this.hasSubmitButtonTarget) {
      const hasValue = event.target.value !== "";
      this.submitButtonTarget.classList.toggle(this.hiddenClass, !hasValue);
    }
  }

  // Handle form submission
  submitForm(event) {
    event.preventDefault();

    // Submit the form using regular form submission
    this.formElementTarget.submit();
  }
}
