import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import Link from "@tiptap/extension-link";

const InlineEditor = (targetId) => {
  const initialContent = targetId.innerHTML.trim();

  let cleanedContent = initialContent;

  cleanedContent = cleanedContent.replace(/<p><\/p>/g, "");
  cleanedContent = cleanedContent.replace(/<p>\s*<\/p>/g, "");
  cleanedContent = cleanedContent.replace(/<p>&nbsp;<\/p>/g, "");

  cleanedContent = cleanedContent.replace(/<\/p>\s*<p>/g, "</p><p>");

  cleanedContent = cleanedContent.replace(/\n\s*\n/g, "\n");
  cleanedContent = cleanedContent.replace(/<br>\s*<br>/g, "<br>");

  const bubbleMenuContainer = document.createElement("div");
  bubbleMenuContainer.className = "bubble-menu";
  document.body.appendChild(bubbleMenuContainer);

  // eslint-disable-next-line no-param-reassign
  targetId.innerHTML = "";

  const editor = new Editor({
    element: targetId,
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: "Add your notes here ...",
      }),
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: "tiptap-link",
        },
      }),
      BubbleMenu.configure({
        element: bubbleMenuContainer,
        shouldShow: ({ from, to }) => from !== to,
        tippyOptions: {
          placement: "top",
          arrow: true,
        },
      }),
    ],
    content: cleanedContent,
  });

  const renderBubbleMenu = () => {
    bubbleMenuContainer.innerHTML = `
      <div class="bubble-menu-content">
        <div class="bubble-menu-group">
          <button class="bubble-menu-button" data-command="paragraph" title="Normal text">
            <span class="bubble-menu-text">T</span>
          </button>
          <button class="bubble-menu-button" data-command="heading" title="Heading">
            <span class="bubble-menu-text">H1</span>
          </button>
        </div>
        <div class="bubble-menu-group">
          <button class="bubble-menu-button" data-command="bulletList" title="Bullet list">
            <span class="material-symbols-outlined">format_list_bulleted</span>
          </button>
          <button class="bubble-menu-button" data-command="orderedList" title="Numbered list">
            <span class="material-symbols-outlined">format_list_numbered</span>
          </button>
        </div>
        <div class="bubble-menu-group">
          <button class="bubble-menu-button" data-command="bold" title="Bold">
            <span class="material-symbols-outlined">format_bold</span>
          </button>
          <button class="bubble-menu-button" data-command="italic" title="Italic">
            <span class="material-symbols-outlined">format_italic</span>
          </button>
        </div>
        <div class="bubble-menu-group">
          <button class="bubble-menu-button" data-command="link" title="Insert link">
            <span class="material-symbols-outlined">link</span>
          </button>
        </div>
      </div>
    `;

    const buttons = bubbleMenuContainer.querySelectorAll(".bubble-menu-button");
    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        const { command } = button.dataset;

        switch (command) {
          case "paragraph":
            editor.chain().focus().setParagraph().run();
            break;
          case "heading":
            editor.chain().focus().toggleHeading({ level: 1 }).run();
            break;
          case "bulletList":
            editor.chain().focus().toggleBulletList().run();
            break;
          case "orderedList":
            editor.chain().focus().toggleOrderedList().run();
            break;
          case "bold":
            editor.chain().focus().toggleBold().run();
            break;
          case "italic":
            editor.chain().focus().toggleItalic().run();
            break;
          case "link": {
            const url = prompt("Enter URL");
            if (url) {
              editor.chain().focus().setLink({ href: url }).run();
            } else {
              editor.chain().focus().unsetLink().run();
            }
            break;
          }
          default:
            break;
        }
      });
    });

    const updateActiveButtons = () => {
      buttons.forEach((button) => {
        const { command } = button.dataset;

        switch (command) {
          case "paragraph":
            button.classList.toggle("is-active", editor.isActive("paragraph"));
            break;
          case "heading":
            button.classList.toggle(
              "is-active",
              editor.isActive("heading", { level: 1 }),
            );
            break;
          case "bulletList":
            button.classList.toggle("is-active", editor.isActive("bulletList"));
            break;
          case "orderedList":
            button.classList.toggle(
              "is-active",
              editor.isActive("orderedList"),
            );
            break;
          case "bold":
            button.classList.toggle("is-active", editor.isActive("bold"));
            break;
          case "italic":
            button.classList.toggle("is-active", editor.isActive("italic"));
            break;
          case "link":
            button.classList.toggle("is-active", editor.isActive("link"));
            break;
          default:
            break;
        }
      });
    };

    editor.on("selectionUpdate", updateActiveButtons);
    editor.on("transaction", updateActiveButtons);
  };

  renderBubbleMenu();

  // Function to clean up the bubble menu container
  const cleanup = () => {
    if (bubbleMenuContainer && document.body.contains(bubbleMenuContainer)) {
      document.body.removeChild(bubbleMenuContainer);
    }
  };

  const wrapper = {
    _editor: editor,

    getText() {
      return editor.state.doc.textContent;
    },

    getHTML() {
      try {
        const editorElement = editor.options.element;
        const proseMirror = editorElement.querySelector(".ProseMirror");
        if (proseMirror) {
          let html = proseMirror.innerHTML;

          html = html.replace(/<p><\/p>/g, "");
          html = html.replace(/<p>\s*<\/p>/g, "");
          html = html.replace(/<p>&nbsp;<\/p>/g, "");

          html = html.replace(/<\/p>\s*<p>/g, "</p><p>");

          html = html.replace(/\n\s*\n/g, "\n");
          html = html.replace(/<br>\s*<br>/g, "<br>");

          html = html.replace(/<\/div>\s*<div>/g, "</div><div>");

          return html;
        }
        return editor.getHTML();
      } catch (e) {
        return "";
      }
    },

    listeners: {},
    on(event, callback) {
      if (!this.listeners[event]) {
        this.listeners[event] = [];
      }
      this.listeners[event].push(callback);

      if (event === "text-change") {
        editor.on("update", () => {
          callback();
        });
      }
    },

    // Method to destroy the editor and clean up resources
    destroy() {
      if (editor) {
        editor.destroy();
      }
      cleanup();
    },
  };

  // Add event listener to clean up when the parent element is removed from DOM
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "childList") {
        const removedNodes = Array.from(mutation.removedNodes);
        if (
          removedNodes.some(
            (node) => node === targetId || node.contains(targetId),
          )
        ) {
          cleanup();
          observer.disconnect();
        }
      }
    });
  });

  // Start observing the document body for changes
  observer.observe(document.body, { childList: true, subtree: true });

  return wrapper;
};

export default InlineEditor;
