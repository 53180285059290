import { Controller } from "@hotwired/stimulus";
import { useStickyFooterMultiselect } from "../../behaviors/use_sticky_footer_multiselect";

export default class WorkStreamAssignObjectsFormController extends Controller {
  static targets = [
    "formSelectFieldElement",
    "formSelectFieldErrorList",
    "formSelectFieldWrapper",
    "checkboxFieldElement",
    "selectAllCheckboxFieldElement",
    "selectAllCheckboxFieldLabel",
    "submitButton",
    "stickyFooter",
  ];

  static classes = [
    "hidden",
    "errorFieldWrapper",
    "checkboxSemiSelected",
    "cardSelected",
  ];

  static values = {
    labelTemplateString: String,
  };

  connect() {
    useStickyFooterMultiselect(this);
    // Fix dropdown functionality
    this.setupSafeDropdown();
  }

  setupSafeDropdown() {
    // Make the dropdown work safely
    if (this.hasFormSelectFieldElementTarget) {
      const select = this.formSelectFieldElementTarget;

      // Prevent the default Bootstrap dropdown behavior which is causing the error
      select.addEventListener("click", (e) => {
        // Stop event propagation to prevent Bootstrap's built-in handlers
        e.stopPropagation();

        // Instead of relying on Bootstrap's dropdown, manually toggle a dropdown
        const dropdownMenu = select.nextElementSibling;
        if (dropdownMenu && dropdownMenu.classList.contains("dropdown-menu")) {
          if (dropdownMenu.classList.contains("show")) {
            dropdownMenu.classList.remove("show");
          } else {
            dropdownMenu.classList.add("show");
          }
        }
      });

      // Close dropdown when clicking outside
      document.addEventListener("click", (e) => {
        if (!select.contains(e.target)) {
          const dropdownMenu = select.nextElementSibling;
          if (
            dropdownMenu &&
            dropdownMenu.classList.contains("dropdown-menu")
          ) {
            dropdownMenu.classList.remove("show");
          }
        }
      });
    }
  }

  // Safe implementation of handleSelectValue_changed
  handleSelectValue_changed() {
    if (
      this.hasFormSelectFieldElementTarget &&
      this.hasFormSelectFieldErrorListTarget
    ) {
      this.formSelectFieldErrorListTarget.innerHTML = "";
    }

    if (this.hasFormSelectFieldWrapperTarget) {
      this.formSelectFieldWrapperTarget.classList.remove(
        this.errorFieldWrapperClass,
      );
    }
  }
}
