import { Controller } from "@hotwired/stimulus";
import InlineEditor from "../../components/inline_editor";

export default class EmailEditorController extends Controller {
  static targets = ["inlineEditor"];

  isDirty = false;

  connect() {
    this.editor = InlineEditor(this.inlineEditorTarget);
    const scopedThis = this; // As you can't access this from the function below
    this.editor.on("text-change", () => {
      scopedThis.isDirty = true;
    });
  }

  disconnect() {
    // Clean up the editor and bubble menu when the controller is disconnected
    if (this.editor && typeof this.editor.destroy === "function") {
      this.editor.destroy();
    }
  }

  copyContentToClipboard() {
    const content = this.editor.getText();
    const htmlContent = this.editor.getHTML();
    const clipboardItem = new ClipboardItem({
      "text/html": new Blob([htmlContent], { type: "text/html" }),
      "text/plain": new Blob([content], { type: "text/plain" }),
    });
    navigator.clipboard.write([clipboardItem]).then(
      () => {},
      // eslint-disable-next-line no-unused-vars
      (error) => {},
    );
  }
}
