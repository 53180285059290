import { Controller } from "@hotwired/stimulus";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";
import { useStreamedResponse, useSpeechRecognition } from "../../behaviors";
import { StreamingResponseEventType } from "../../behaviors/use_streamed_response";

export default class extends Controller {
  static values = {
    startStreaming: Boolean,
    initialQuestion: String,
  };

  static targets = [
    "form",
    "textQuestion",
    "thinking",
    "questionAndAnswerContainer",
    "questionContainer",
    "questionAndNameContainer",
    "answerContainer",
    "sendButton",
    "messagesContainer",
    "micButton",
  ];

  connect() {
    useStreamedResponse(this);
    if (this.hasMicButtonTarget) {
      // Initialize speech recognition
      useSpeechRecognition(this);
    }

    if (this.startStreamingValue === true) {
      const encodedInitialQuestion = this.getEncodedQueryString(
        this.initialQuestionValue,
      );
      this.startStreaming(encodedInitialQuestion);
    }
    this.scrollToBottom();

    this.reloadThreadsWithCurrentThreadIdIfNecessary();
  }

  // eslint-disable-next-line class-methods-use-this
  reloadThreadsWithCurrentThreadIdIfNecessary() {
    /**
     * If we have a thread ID in the URL, get the thread list turbo frame and reload it by appending the thread ID
     * as a GET parameter in order to mark the active thread.
     * Only do it if the thread ID is different than the one already in the thread list URL.
     */
    const threadMessagesTurboFrameUrl = new URL(
      document.getElementById("chatbot-interactions-thread-messages").src,
    );
    const urlParams = new URLSearchParams(threadMessagesTurboFrameUrl.search);
    const currentMessagesThreadId = urlParams.get("thread_id");
    const threadsTurboFrame = document.getElementById(
      "chatbot-interactions-threads",
    );
    if (currentMessagesThreadId) {
      const threadsTurboFrameUrl = new URL(threadsTurboFrame.src);
      const threadsUrlParams = new URLSearchParams(threadsTurboFrameUrl.search);
      const currentThreadsThreadId = threadsUrlParams.get("thread_id");
      if (currentMessagesThreadId !== currentThreadsThreadId) {
        threadsUrlParams.set("thread_id", currentMessagesThreadId);
        threadsTurboFrameUrl.search = threadsUrlParams.toString();
        threadsTurboFrame.src = threadsTurboFrameUrl.toString();
      }
    }
  }

  scrollToBottonIfOnBottom() {
    const isScrolledToBottom =
      this.messagesContainerTarget.scrollHeight -
        this.messagesContainerTarget.clientHeight <=
      this.messagesContainerTarget.scrollTop + 50;
    if (isScrolledToBottom) {
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    this.messagesContainerTarget.scrollTop =
      this.messagesContainerTarget.scrollHeight;
  }

  handleStreamingResponseEvent(eventType, eventData) {
    if (eventType === StreamingResponseEventType.SUBMIT_START) {
      this.handleSubmitStart();
    } else if (eventType === StreamingResponseEventType.SUBMIT_END) {
      this.handleSubmitEnd();
    } else if (eventType === StreamingResponseEventType.RESPONSE_RECEIVED) {
      this.answerContainerTarget.innerHTML = "";
    } else if (
      eventType === StreamingResponseEventType.PARTIAL_RESPONSE_RECEIVED
    ) {
      this.handlePartialResponseReceived(eventData);
    } else if (eventType === StreamingResponseEventType.STREAMING_PROCESS_END) {
      this.textQuestionTarget.value = "";
    }
  }

  handleSubmitStart() {
    const question = this.textQuestionTarget.value;
    this.thinkingTarget.classList.remove("d-none");
    this.textQuestionTarget.value = "";
    this.textQuestionTarget.disabled = true;
    this.sendButtonTarget.disabled = true;
    this.questionAndAnswerContainerTarget.classList.remove("d-none");
    this.questionAndNameContainerTarget.classList.remove("d-none");
    this.answerContainerTarget.innerHTML = "";

    if (this.startStreamingValue === true) {
      // we already have the initial question visible as the first message
      this.questionAndNameContainerTarget.classList.add("d-none");
    } else {
      this.questionContainerTarget.innerHTML = question;
    }

    this.scrollToBottom();
  }

  /* eslint-disable-next-line class-methods-use-this */
  handleSubmitEnd() {
    // refresh the turbo frame when the full response is received
    const turboFrame = document.getElementById(
      "chatbot-interactions-thread-messages",
    );
    turboFrame.src = turboFrame.src; // eslint-disable-line no-self-assign
  }

  handlePartialResponseReceived(eventData) {
    let conversation = this.answerContainerTarget.innerHTML;
    const value = eventData;
    const decoder = new TextDecoder();
    const decodedValue = decoder.decode(value);
    conversation += decodedValue;

    conversation = conversation.replace(/(?:\r\n|\r|\n)/g, "<br>");
    this.answerContainerTarget.innerHTML = conversation;

    this.scrollToBottonIfOnBottom();
  }

  getQueryString() {
    const question = this.textQuestionTarget.value;
    return this.getEncodedQueryString(question);
  }

  // eslint-disable-next-line class-methods-use-this
  getEncodedQueryString(question) {
    return `content=${encodeURIComponent(question)}`;
  }

  send() {
    trackEventWithDataToAmplitudeAndGoogle(
      "Question",
      "chatbot",
      "Work Stream Chatbot - Question",
      {
        query: this.textQuestionTarget.value,
      },
    );
  }
}
