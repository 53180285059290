/* eslint import/prefer-default-export: "off" */
import { fillTemplate } from "../utils/templates";

/**
 * Allow a controller to use a multiselect sticky header bar with one form field.
 * The controller must define the following targets:
 * - `formSelectFieldElement`
 * - `formSelectFieldErrorList`
 * - `formSelectFieldWrapper`
 * - `checkboxFieldElement`
 * - `selectAllCheckboxFieldElement`
 * - `selectAllCheckboxFieldLabel`
 * - `submitButton`
 * - `stickyFooter`
 *
 * The controller can add custom checkbox click behavior by defining a `onCheckboxClick` method.
 */
export const useStickyFooterMultiselect = (controller) => {
  Object.assign(controller, {
    toggleStickyFooter() {
      if (this.hasCheckboxChecked()) {
        this.showStickyFooter();
      } else {
        this.hideStickyFooter();
      }
    },

    hasCheckboxChecked() {
      const firstCheckedCheckbox = this.checkboxFieldElementTargets.find(
        (el) => el.checked === true,
      );
      return firstCheckedCheckbox !== undefined;
    },

    showStickyFooter() {
      this.stickyFooterTarget.classList.remove(this.hiddenClass);
    },

    hideStickyFooter() {
      this.stickyFooterTarget.classList.add(this.hiddenClass);
      this.element.style.marginBottom = 0;
    },

    handleCheckbox_click(evt) {
      this.toggleStickyFooter();
      this.updateSelectAllFieldElementAndLabel();

      if (controller.onCheckboxClick !== undefined) {
        controller.onCheckboxClick(evt);
      }
    },

    updateSelectAllFieldElementAndLabel() {
      const count = this.checkboxFieldElementTargets.filter(
        (el) => el.checked === true,
      ).length;

      const value = fillTemplate(this.labelTemplateStringValue, { count });

      this.selectAllCheckboxFieldLabelTarget.innerText = value;

      if (count === this.checkboxFieldElementTargets.length) {
        this.selectAllCheckboxFieldElementTarget.checked = true;
        this.selectAllCheckboxFieldElementTarget.classList.remove(
          this.checkboxSemiSelectedClass,
        );
      } else if (count > 0) {
        this.selectAllCheckboxFieldElementTarget.checked = false;
        this.selectAllCheckboxFieldElementTarget.classList.add(
          this.checkboxSemiSelectedClass,
        );
      } else {
        this.selectAllCheckboxFieldElementTarget.checked = false;
        this.selectAllCheckboxFieldElementTarget.classList.remove(
          this.checkboxSemiSelectedClass,
        );
      }
    },

    handleSelectValue_changed() {
      if (this.formSelectFieldElementTarget.value !== "") {
        this.formSelectFieldErrorListTarget.innerHTML = "";
        this.formSelectFieldWrapperTarget.classList.remove(
          this.errorFieldWrapperClass,
        );
      }
    },

    handleSelectAllCheckbox_click() {
      if (this.areAllCheckboxesChecked() === true) {
        this.unselectCheckboxes();
        this.toggleStickyFooter();
      } else {
        this.selectAllCheckboxes();
      }
      this.updateSelectAllFieldElementAndLabel();
    },

    areAllCheckboxesChecked() {
      const uncheckedCheckbox = this.checkboxFieldElementTargets.find(
        (el) => el.checked === false,
      );
      return uncheckedCheckbox === undefined;
    },

    unselectCheckboxes() {
      this.checkboxFieldElementTargets.forEach((el) => {
        el.checked = false; // eslint-disable-line no-param-reassign
      });
    },

    selectAllCheckboxes() {
      this.checkboxFieldElementTargets.forEach((el) => {
        el.checked = true; // eslint-disable-line no-param-reassign
      });
    },

    initStickyFooter() {
      if (this.hasFormSelectFieldElementTarget) {
        this.formSelectFieldElementTarget.required = false;
        if (this.formSelectFieldErrorListTarget.childElementCount === 0) {
          this.toggleStickyFooter();
        } else {
          this.updateSelectAllFieldElementAndLabel();
        }
      }
    },
  });

  controller.initStickyFooter();
};
