import $ from "jquery";
import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";
import ActionApiService from "../../services/action_api_service";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";

/**
 * Action controller for handling action item editing
 */
export default class extends Controller {
  static targets = [
    "form",
    "editButton",
    "saveButton",
    "cancelButton",
    "deleteButton",
    "addUsersButton",
    "addUsersButtonContainer",
    "avatarGroup",
    "avatarTemplate",
    "title",
    "titleDisplay",
    "status",
    "statusDisplay",
    "dueDate",
    "dueDateDisplay",
    "dueDateEditContainer",
    "assignedUsers",
    "userModal",
    "userSearchResults",
    "availableUsersData",
    "favoriteButton",
    "completeButton",
  ];

  static values = {
    actionId: String,
  };

  connect() {
    // Initialize API service
    this.apiService = new ActionApiService();

    // Initialize user selection
    this.selectedUsers = new Set();
    this.allUsers = [];

    // Store original values for cancel operation
    this.originalValues = {};
    this.storeOriginalValues();

    // Load all users from the hidden div
    if (this.hasAvailableUsersDataTarget) {
      this.loadAvailableUsers();
    }

    // Initialize User Selection modal
    this.userModal = $(this.userModalTarget);

    // Add event listeners to sync form fields
    if (this.hasTitleTarget) {
      const titleInput = this.titleTarget.querySelector("input");
      if (titleInput) {
        titleInput.addEventListener("input", () => {
          this.formTarget.querySelector("#form-title").value = titleInput.value;
        });
      }
    }

    if (this.hasStatusTarget) {
      const statusSelect = this.statusTarget.querySelector("select");
      if (statusSelect) {
        statusSelect.addEventListener("change", () => {
          this.formTarget.querySelector("#form-status").value =
            statusSelect.value;
        });
      }
    }

    if (this.hasDueDateTarget) {
      const dueDateInput = this.dueDateTarget.querySelector("input");
      if (dueDateInput) {
        dueDateInput.addEventListener("change", () => {
          this.formTarget.querySelector("#form-due-date").value =
            dueDateInput.value;
        });
      }
    }

    // Initialize tooltips
    this.initializeTooltips();
  }

  loadAvailableUsers() {
    // Get all users from the hidden div
    const userElements =
      this.availableUsersDataTarget.querySelectorAll(".user-data");
    this.allUsers = [];

    userElements.forEach((element) => {
      this.allUsers.push({
        id: element.getAttribute("data-user-id"),
        fullName: element.getAttribute("data-full-name"),
        email: element.getAttribute("data-email"),
        avatarUrl: element.getAttribute("data-avatar-url"),
      });
    });

    // Sort users by name
    this.allUsers.sort((a, b) => {
      // Handle null or undefined fullName values
      if (!a.fullName) return 1; // Move null values to the end
      if (!b.fullName) return -1; // Move null values to the end
      return a.fullName.localeCompare(b.fullName);
    });
  }

  storeOriginalValues() {
    if (this.hasTitleTarget) {
      const titleInput = this.titleTarget.querySelector("input");
      if (titleInput) {
        this.originalValues.title = titleInput.value;
        this.formTarget.querySelector("#form-title").value = titleInput.value;
      }
    }

    if (this.hasStatusTarget) {
      const statusSelect = this.statusTarget.querySelector("select");
      if (statusSelect) {
        this.originalValues.status = statusSelect.value;
        this.formTarget.querySelector("#form-status").value =
          statusSelect.value;
      }
    }

    if (this.hasDueDateTarget) {
      const dueDateInput = this.dueDateTarget.querySelector("input");
      if (dueDateInput) {
        this.originalValues.dueDate = dueDateInput.value;
        this.formTarget.querySelector("#form-due-date").value =
          dueDateInput.value;
      }
    }

    if (this.hasAssignedUsersTarget) {
      const assignedUsersSelect =
        this.assignedUsersTarget.querySelector("select");
      if (assignedUsersSelect) {
        this.originalValues.assignedUsers = Array.from(
          assignedUsersSelect.selectedOptions,
        ).map((opt) => opt.value);

        // Initialize selected users from the current selection
        this.selectedUsers = new Set(
          this.originalValues.assignedUsers.map((id) => String(id)),
        );
      }
    }
  }

  edit() {
    // Hide display elements and show form fields
    if (this.hasTitleTarget && this.hasTitleDisplayTarget) {
      this.titleDisplayTarget.classList.add("d-none");
      this.titleTarget.classList.remove("d-none");
    }

    if (this.hasStatusTarget && this.hasStatusDisplayTarget) {
      this.statusDisplayTarget.classList.add("d-none");
      this.statusTarget.classList.remove("d-none");
    }

    if (
      this.hasDueDateTarget &&
      this.hasDueDateDisplayTarget &&
      this.hasDueDateEditContainerTarget
    ) {
      this.dueDateDisplayTarget.classList.add("d-none");
      this.dueDateEditContainerTarget.classList.remove("d-none");
    }

    // Hide the add users button in edit mode
    if (this.hasAddUsersButtonContainerTarget) {
      this.addUsersButtonContainerTarget.classList.add("d-none");
    }

    // Hide the avatar group in edit mode
    if (this.hasAvatarGroupTarget) {
      this.avatarGroupTarget.classList.add("d-none");
    }

    // Hide the delete button in edit mode
    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.classList.add("d-none");
    }

    // Hide the favorite button in edit mode
    if (this.hasFavoriteButtonTarget) {
      this.favoriteButtonTarget.classList.add("d-none");
    }

    // Hide the complete button in edit mode
    if (this.hasCompleteButtonTarget) {
      this.completeButtonTarget.classList.add("d-none");
    }

    // Track edit mode event
    trackEventWithDataToAmplitudeAndGoogle(
      "edit",
      "action",
      "Action Edit Mode",
      { action_id: this.actionIdValue },
    );

    // Show save and cancel buttons, hide edit button
    this.editButtonTarget.classList.add("d-none");
    this.saveButtonTarget.classList.remove("d-none");
    this.cancelButtonTarget.classList.remove("d-none");
  }

  async save() {
    try {
      // Update hidden form fields with current values before form submission
      if (this.hasTitleTarget) {
        const titleInput = this.titleTarget.querySelector("textarea");
        if (titleInput) {
          this.formTarget.querySelector("#form-title").value = titleInput.value;
        }
      }

      if (this.hasStatusTarget) {
        const statusSelect = this.statusTarget.querySelector("select");
        if (statusSelect) {
          this.formTarget.querySelector("#form-status").value =
            statusSelect.value;
        }
      }

      if (this.hasDueDateTarget) {
        const dueDateInput = this.dueDateTarget.querySelector("input");
        if (dueDateInput) {
          this.formTarget.querySelector("#form-due-date").value =
            dueDateInput.value;
        }
      }

      // Track action edit/save event
      trackEventWithDataToAmplitudeAndGoogle("save", "action", "Action Saved", {
        action_id: this.actionIdValue,
      });

      // Instead of creating a FormData object that's not used, we'll ensure all form fields are updated
      // before submitting the form
      // Submit the form with the updated values
      this.formTarget.requestSubmit();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error saving action:", error);
    }
  }

  async delete(event) {
    event.preventDefault();

    try {
      // Get the deleted field input or create one if it doesn't exist
      let deletedInput = this.formTarget.querySelector("#form-deleted");
      if (!deletedInput) {
        deletedInput = document.createElement("input");
        deletedInput.type = "hidden";
        deletedInput.id = "form-deleted";
        deletedInput.name = "deleted";
        this.formTarget.appendChild(deletedInput);
      }

      // Toggle between deleted and not deleted
      const newDeletedStatus = deletedInput.value === "True" ? "False" : "True";
      deletedInput.value = newDeletedStatus;

      // Update the UI
      const deleteButton = this.deleteButtonTarget;

      if (newDeletedStatus === "True") {
        deleteButton.classList.remove("delete-unchecked");
        deleteButton.classList.add("delete-checked");
      } else {
        deleteButton.classList.remove("delete-checked");
        deleteButton.classList.add("delete-unchecked");
      }

      // Track trash/untrash event
      const eventName = newDeletedStatus === "True" ? "trash" : "untrash";
      const eventLabel =
        newDeletedStatus === "True" ? "Action Trashed" : "Action Untrashed";
      trackEventWithDataToAmplitudeAndGoogle(eventName, "action", eventLabel, {
        action_id: this.actionIdValue,
      });

      // Submit the form to save the changes
      this.formTarget.requestSubmit();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error deleting action:", error);
    }
  }

  cancel() {
    // Restore original values
    this.restoreOriginalValues();

    // Hide form fields and show display elements
    if (this.hasTitleTarget && this.hasTitleDisplayTarget) {
      this.titleTarget.classList.add("d-none");
      this.titleDisplayTarget.classList.remove("d-none");
    }

    if (this.hasStatusTarget && this.hasStatusDisplayTarget) {
      this.statusTarget.classList.add("d-none");
      this.statusDisplayTarget.classList.remove("d-none");
    }

    if (
      this.hasDueDateTarget &&
      this.hasDueDateDisplayTarget &&
      this.hasDueDateEditContainerTarget
    ) {
      this.dueDateEditContainerTarget.classList.add("d-none");
      this.dueDateDisplayTarget.classList.remove("d-none");
    }

    // Show the add users button when canceling edit mode
    if (this.hasAddUsersButtonContainerTarget) {
      this.addUsersButtonContainerTarget.classList.remove("d-none");
    }

    // Show the avatar group when canceling edit mode
    if (this.hasAvatarGroupTarget) {
      this.avatarGroupTarget.classList.remove("d-none");
    }

    // Show the delete button when canceling edit mode
    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.classList.remove("d-none");
    }

    // Show the favorite button when canceling edit mode
    if (this.hasFavoriteButtonTarget) {
      this.favoriteButtonTarget.classList.remove("d-none");
    }

    // Show the complete button when canceling edit mode
    if (this.hasCompleteButtonTarget) {
      this.completeButtonTarget.classList.remove("d-none");
    }

    // Hide save and cancel buttons, show edit button
    this.saveButtonTarget.classList.add("d-none");
    this.cancelButtonTarget.classList.add("d-none");
    this.editButtonTarget.classList.remove("d-none");
  }

  enableFormFields() {
    if (this.hasTitleTarget) {
      const titleInput = this.titleTarget.querySelector("input");
      if (titleInput) titleInput.disabled = false;
    }
  }

  disableFormFields() {
    if (this.hasTitleTarget) {
      const titleInput = this.titleTarget.querySelector("input");
      if (titleInput) titleInput.disabled = true;
    }
  }

  restoreOriginalValues() {
    if (this.hasTitleTarget) {
      const titleInput = this.titleTarget.querySelector("input");
      if (titleInput && this.originalValues.title) {
        titleInput.value = this.originalValues.title;
        this.formTarget.querySelector("#form-title").value =
          this.originalValues.title;
      }
    }

    if (this.hasStatusTarget) {
      const statusSelect = this.statusTarget.querySelector("select");
      if (statusSelect && this.originalValues.status) {
        statusSelect.value = this.originalValues.status;
        this.formTarget.querySelector("#form-status").value =
          this.originalValues.status;
      }
    }

    if (this.hasDueDateTarget) {
      const dueDateInput = this.dueDateTarget.querySelector("input");
      if (dueDateInput) {
        dueDateInput.value = this.originalValues.dueDate || "";
        this.formTarget.querySelector("#form-due-date").value =
          this.originalValues.dueDate || "";
      }
    }

    if (this.hasAssignedUsersTarget) {
      const assignedUsersSelect =
        this.assignedUsersTarget.querySelector("select");
      if (assignedUsersSelect && this.originalValues.assignedUsers) {
        // Clear all selections
        for (let i = 0; i < assignedUsersSelect.options.length; i += 1) {
          assignedUsersSelect.options[i].selected = false;
        }

        // Select the original options
        for (let i = 0; i < assignedUsersSelect.options.length; i += 1) {
          if (
            this.originalValues.assignedUsers.includes(
              assignedUsersSelect.options[i].value,
            )
          ) {
            assignedUsersSelect.options[i].selected = true;
          }
        }

        // Reset the selected users set
        this.selectedUsers = new Set(this.originalValues.assignedUsers);
      }
    }
  }

  openUserModal() {
    if (!this.hasUserModalTarget) return;

    // Make sure allUsers is loaded
    if (this.allUsers?.length === 0 && this.hasAvailableUsersDataTarget) {
      this.loadAvailableUsers();
    }

    // Populate available users
    this.populateAvailableUsers();

    // Show the modal
    this.userModal.modal("show");

    // Clear search input
    const searchInput = this.userModal.find("#user-search-input");
    if (searchInput) {
      searchInput.value = "";
    }
  }

  hideUserModal() {
    if (!this.hasUserModalTarget) return;
    this.userModal.modal("hide");
  }

  filterUsers(event) {
    const searchTerm = event.target.value;
    this.populateAvailableUsers(searchTerm);
  }

  populateAvailableUsers(searchTerm = "") {
    if (!this.hasUserSearchResultsTarget) return;

    // Clear previous results
    this.userSearchResultsTarget.innerHTML = "";

    // Filter users based on search term
    const filteredUsers = this.allUsers
      .filter(
        (user) =>
          !searchTerm ||
          user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .map((user) => ({
        ...user,
        selected: this.selectedUsers.has(String(user.id)),
      }));

    // Create a container for the user list
    const userListContainer = document.createElement("div");
    userListContainer.className = "user-list";
    this.userSearchResultsTarget.appendChild(userListContainer);

    // Add users to the results list
    filteredUsers.forEach((user) => {
      const userElement = document.createElement("div");
      userElement.className =
        "user-item d-flex align-items-center p-2 border-bottom";
      userElement.style.cursor = "pointer";
      userElement.innerHTML = `
        <div class="form-check me-2">
          <input type="checkbox" class="form-check-input" id="user-${user.id}" ${user.selected ? "checked" : ""}>
        </div>
        <div class="d-flex align-items-center flex-grow-1">
          <div class="avatar-xs me-2">
            <img width="24" height="24" src="${user.avatarUrl}" class="rounded-circle" alt="${user.fullName}">
          </div>
          <div>
            <div class="fw-bold">${user.fullName}</div>
            <div class="small text-muted">${user.email}</div>
          </div>
        </div>
      `;

      // Add click event to select/deselect user
      userElement.addEventListener("click", (event) => {
        // Only handle the click if it's not directly on the checkbox
        if (event.target.type !== "checkbox") {
          const checkbox = userElement.querySelector('input[type="checkbox"]');
          checkbox.checked = !checkbox.checked;

          // Trigger the change event to ensure it's processed
          const changeEvent = new Event("change");
          checkbox.dispatchEvent(changeEvent);
        }
      });

      // Add change event to the checkbox
      const checkbox = userElement.querySelector('input[type="checkbox"]');
      checkbox.addEventListener("change", () => {
        if (checkbox.checked) {
          this.addUser(user);
        } else {
          this.removeUser(user.id);
        }
      });

      userListContainer.appendChild(userElement);
    });

    // Show empty message if no users match the search
    if (filteredUsers.length === 0) {
      const emptyMessage = document.createElement("div");
      emptyMessage.className = "text-center p-3 text-muted";
      emptyMessage.textContent =
        this.userSearchResultsTarget.dataset.emptyMessage || "No users found";
      userListContainer.appendChild(emptyMessage);
    }
  }

  addUser(user) {
    this.selectedUsers.add(String(user.id));
    const assignedUsersSelect =
      this.assignedUsersTarget.querySelector("select");
    if (assignedUsersSelect) {
      for (let i = 0; i < assignedUsersSelect.options.length; i += 1) {
        if (assignedUsersSelect.options[i].value === String(user.id)) {
          assignedUsersSelect.options[i].selected = true;
          break;
        }
      }
    }
  }

  removeUser(userId) {
    this.selectedUsers.delete(String(userId));
    const assignedUsersSelect =
      this.assignedUsersTarget.querySelector("select");
    if (assignedUsersSelect) {
      for (let i = 0; i < assignedUsersSelect.options.length; i += 1) {
        if (assignedUsersSelect.options[i].value === String(userId)) {
          assignedUsersSelect.options[i].selected = false;
          break;
        }
      }
    }
  }

  saveUsers() {
    this.hideUserModal();

    // Track user assignment event
    const assignedUserIds = Array.from(this.selectedUsers);
    trackEventWithDataToAmplitudeAndGoogle(
      "assign_users",
      "action",
      "Users Assigned to Action",
      {
        action_id: this.actionIdValue,
        user_count: assignedUserIds.length,
        user_ids: assignedUserIds,
      },
    );

    // Update the avatar display in the action card
    this.updateAvatarDisplay();
    // Submit the form to save the changes
    this.save();
  }

  saveNewAction() {
    // Submit the action-add-form form
    const form = this.formTarget;
    if (form && form.id === "action-add-form") {
      form.requestSubmit();
    }
  }

  updateAvatarDisplay() {
    // Clear existing avatars except the add button
    const avatarGroup = this.avatarGroupTarget;
    const addButtonContainer = this.addUsersButtonContainerTarget;

    // Remove all children except the add button container
    while (
      avatarGroup.firstChild &&
      avatarGroup.firstChild !== addButtonContainer
    ) {
      avatarGroup.removeChild(avatarGroup.firstChild);
    }

    // Get the assigned users from the hidden field
    const assignedUsersField = this.assignedUsersTarget.querySelector("select");
    const selectedOptions = Array.from(assignedUsersField.selectedOptions);

    // Create avatar for each assigned user
    selectedOptions.forEach((option) => {
      const userId = option.value;
      const user = this.allUsers.find((u) => u.id === parseInt(userId, 10));

      if (user) {
        // Clone the avatar template
        const template = this.avatarTemplateTarget.content.cloneNode(true);
        const avatarItem = template.querySelector(".avatar-group-item");
        avatarItem.dataset.userId = user.id;

        // Set the user data in the cloned template
        const img = avatarItem.querySelector("img");
        img.src = user.avatarUrl;
        img.title = user.fullName;
        img.alt = user.fullName;

        // Insert before the add button
        avatarGroup.insertBefore(avatarItem, addButtonContainer);
      }
    });

    // Initialize tooltips on the newly added avatars
    this.initializeTooltips();

    // Update the button visibility based on edit mode
    if (this.isEditMode) {
      addButtonContainer.classList.remove("d-none");
    } else {
      addButtonContainer.classList.add("d-none");
    }
  }

  /**
   * Initialize tooltips on elements with data-toggle="tooltip" attribute
   */
  initializeTooltips() {
    try {
      // First dispose any existing tooltips to prevent duplicates or stuck tooltips
      this.disposeTooltips();

      // Find all tooltip elements within this controller's element
      const tooltipTriggerList = [
        ...this.element.querySelectorAll('[data-toggle="tooltip"]'),
        ...this.element.querySelectorAll('[data-bs-toggle="tooltip"]'),
      ];

      // Initialize each tooltip using getOrCreateInstance to avoid ESLint no-new error
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        if (tooltipTriggerEl && tooltipTriggerEl.parentElement) {
          Tooltip.getOrCreateInstance(tooltipTriggerEl);
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("Error initializing tooltips:", error);
    }
  }

  /**
   * Dispose of all tooltips within this controller's element
   * This prevents tooltips from getting stuck on the screen
   */
  disposeTooltips() {
    try {
      // Find all tooltip elements
      const tooltipElements = [
        ...this.element.querySelectorAll('[data-toggle="tooltip"]'),
        ...this.element.querySelectorAll('[data-bs-toggle="tooltip"]'),
      ];

      // Dispose each tooltip instance
      tooltipElements.forEach((element) => {
        try {
          const tooltipInstance = Tooltip.getInstance(element);
          if (tooltipInstance) {
            tooltipInstance.dispose();
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn("Error disposing individual tooltip:", error);
        }
      });

      // Also remove any orphaned tooltip elements from the DOM
      document.querySelectorAll(".tooltip").forEach((tooltip) => {
        tooltip.remove();
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("Error disposing tooltips:", error);
    }
  }

  /**
   * Clean up when the controller is disconnected
   */
  disconnect() {
    try {
      // Make sure to dispose all tooltips when the controller disconnects
      this.disposeTooltips();

      // Remove any remaining event listeners or cleanup tasks
      if (this.userSearchTimeout) {
        clearTimeout(this.userSearchTimeout);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("Error during controller disconnect:", error);
    }
  }

  /**
   * Toggle the favorite (keep) status of an action
   */
  async toggleFavorite(event) {
    event.preventDefault();

    // Toggle the keep value
    const keepInput = this.formTarget.querySelector("#form-keep");
    const currentValue = keepInput.value === "True";
    const newValue = !currentValue;
    keepInput.value = newValue ? "True" : "False";

    // Update the UI
    const favoriteButton = this.favoriteButtonTarget;
    if (newValue) {
      favoriteButton.classList.remove("favorite-unchecked");
      favoriteButton.classList.add("favorite-checked");
    } else {
      favoriteButton.classList.remove("favorite-checked");
      favoriteButton.classList.add("favorite-unchecked");
    }

    // Track star/unstar event
    const eventName = newValue ? "star" : "unstar";
    const eventLabel = newValue ? "Action Starred" : "Action Unstarred";
    trackEventWithDataToAmplitudeAndGoogle(eventName, "action", eventLabel, {
      action_id: this.actionIdValue,
    });

    // Submit the form
    try {
      this.formTarget.requestSubmit();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error saving favorite status:", error);
    }
  }

  // Mark action as completed
  async completeAction(event) {
    event.preventDefault();

    // Set the status to completed
    const statusInput = this.formTarget.querySelector("#form-status");
    const currentStatus = statusInput.value;

    // Toggle between completed and todo
    const newStatus = currentStatus === "completed" ? "todo" : "completed";
    statusInput.value = newStatus;

    // Update the UI
    const completeButton = this.completeButtonTarget;
    if (newStatus === "completed") {
      completeButton.classList.remove("complete-unchecked");
      completeButton.classList.add("complete-checked");
    } else {
      completeButton.classList.remove("complete-checked");
      completeButton.classList.add("complete-unchecked");
    }

    // Track complete/uncomplete event
    const eventName =
      newStatus === "completed" ? "mark_completed" : "unmark_completed";
    const eventLabel =
      newStatus === "completed"
        ? "Action Marked as Completed"
        : "Action Unmarked as Completed";
    trackEventWithDataToAmplitudeAndGoogle(eventName, "action", eventLabel, {
      action_id: this.actionIdValue,
    });

    // Submit the form
    try {
      this.formTarget.requestSubmit();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error completing action:", error);
    }
  }
}
