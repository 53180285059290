/**
 * Action API service for interacting with the Actions API
 */
export default class ActionApiService {
  /**
   * Constructor for the ActionApiService class
   * @param {string} baseUrl - The base URL for the API (default: '/actions/api/actions/')
   */
  constructor(baseUrl = "/actions/api/actions/") {
    this.baseUrl = baseUrl;
    this.csrfToken = this.getCSRFToken();
  }

  /**
   * Get the CSRF token from the cookie
   * @returns {string} The CSRF token
   */
  // eslint-disable-next-line class-methods-use-this
  getCSRFToken() {
    const name = "csrftoken";
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name))
      ?.split("=")[1];
    return cookieValue || "";
  }

  /**
   * Make a request to the API
   * @param {string} url - The URL to make the request to
   * @param {string} method - The HTTP method to use
   * @param {object} data - The data to send with the request
   * @returns {Promise} A promise that resolves to the response data
   */
  async request(url, method = "GET", data = null) {
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.csrfToken,
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    if (data && (method === "POST" || method === "PUT" || method === "PATCH")) {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "An error occurred");
    }

    return response.json();
  }

  /**
   * Get all actions
   * @returns {Promise} A promise that resolves to an array of actions
   */
  async getActions() {
    return this.request(this.baseUrl);
  }

  /**
   * Get a single action by ID
   * @param {string} actionId - The ID of the action to get
   * @returns {Promise} A promise that resolves to the action data
   */
  async getAction(actionId) {
    return this.request(`${this.baseUrl}${actionId}/`);
  }

  /**
   * Create a new action
   * @param {object} actionData - The data for the new action
   * @returns {Promise} A promise that resolves to the created action
   */
  async createAction(actionData) {
    return this.request(this.baseUrl, "POST", actionData);
  }

  /**
   * Update an action
   * @param {string} actionId - The ID of the action to update
   * @param {object} actionData - The data to update the action with
   * @returns {Promise} A promise that resolves to the updated action
   */
  async updateAction(actionId, actionData) {
    return this.request(`${this.baseUrl}${actionId}/`, "PUT", actionData);
  }

  /**
   * Partially update an action
   * @param {string} actionId - The ID of the action to update
   * @param {object} actionData - The data to update the action with
   * @returns {Promise} A promise that resolves to the updated action
   */
  async patchAction(actionId, actionData) {
    return this.request(`${this.baseUrl}${actionId}/`, "PATCH", actionData);
  }

  /**
   * Update a single field of an action
   * @param {string} actionId - The ID of the action to update
   * @param {string} field - The field to update
   * @param {any} value - The new value for the field
   * @returns {Promise} A promise that resolves to the updated action
   */
  async updateField(actionId, field, value) {
    return this.request(`${this.baseUrl}${actionId}/update_field/`, "PATCH", {
      field,
      value,
    });
  }

  /**
   * Delete an action
   * @param {string} actionId - The ID of the action to delete
   * @returns {Promise} A promise that resolves when the action is deleted
   */
  async deleteAction(actionId) {
    return this.request(`${this.baseUrl}${actionId}/`, "DELETE");
  }
}
