import { Controller } from "@hotwired/stimulus";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";

export default class extends Controller {
  static targets = ["searchInput", "filterDropdown"];

  connect() {
    // Initialize Bootstrap dropdown when controller connects
    if (
      typeof window.bootstrap !== "undefined" &&
      this.hasFilterDropdownTarget
    ) {
      // Store the dropdown instance on the controller to avoid 'new' for side effects
      this.dropdown = new window.bootstrap.Dropdown(this.filterDropdownTarget);
    }
  }

  handleSearch() {
    const searchQuery = this.searchInputTarget.value;

    trackEventWithDataToAmplitudeAndGoogle(
      "userSearch",
      "search",
      "Search - User Search",
      {
        query: searchQuery,
      },
    );
  }

  applyFilter(event) {
    event.preventDefault();

    // Use 'this' to satisfy the class-methods-use-this rule
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);
    const { filter } = event.currentTarget.dataset;

    // Keep the current search query
    const query = searchParams.get("q") || "";

    // Create new URL with the selected filter
    let newUrl;
    if (filter === "all") {
      // Remove filter parameter entirely for "All" option
      searchParams.delete("filter");
      newUrl = `?${searchParams.toString()}`;
    } else {
      newUrl = `?q=${query}&filter=${filter}`;
    }

    // Create analytics data and use it for tracking
    const analyticsData = this.getAnalyticsData(query, filter);
    this.trackFilterUsage(analyticsData);

    // Navigate to the filtered results
    window.location.href = newUrl;
  }

  // Helper method to prepare analytics data
  getAnalyticsData(query, filter) {
    return {
      query,
      filter,
      // Add some data that depends on this controller's state
      hasSearchInput: this.hasSearchInputTarget,
      timestamp: new Date().toISOString(),
    };
  }

  // Helper method to track filter usage with this controller's state
  trackFilterUsage(analyticsData) {
    // Use this controller's properties
    const controllerIdentifier = this.identifier;
    const controllerElement = this.element;

    // Log controller information for debugging if needed
    if (this.application.debug) {
      // eslint-disable-next-line no-console
      console.debug(
        `Filter tracked by controller: ${controllerIdentifier}`,
        controllerElement,
      );
    }

    trackEventWithDataToAmplitudeAndGoogle(
      "searchFilter",
      "search",
      "Search - Filter Applied",
      analyticsData,
    );
  }

  disconnect() {
    // Clean up the dropdown when the controller disconnects
    if (this.dropdown) {
      this.dropdown.dispose();
      this.dropdown = null;
    }
  }
}
