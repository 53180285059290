import { Controller } from "@hotwired/stimulus";
import { useStickyFooterMultiselect } from "../../behaviors";
import "../../../scss/components/_table-sticky-footer.scss";

export default class WorkStreamAssignObjectFormController extends Controller {
  static targets = [
    "unassignedObjectCard",
    "checkboxFieldElement",
    "selectAllCheckboxFieldElement",
    "selectAllCheckboxFieldLabel",
    "submitButton",
    "stickyFooter",
  ];

  static classes = [
    "hidden",
    "checkboxSemiSelected",
    "cardSelected",
    "errorFieldWrapper",
  ];

  static values = {
    labelTemplateString: String,
  };

  connect() {
    useStickyFooterMultiselect(this);
    this.toggleStickyFooter();
  }

  // eslint-disable-next-line class-methods-use-this
  clickCheckbox(evt) {
    const checkbox = evt.currentTarget.querySelector("input[type='checkbox']");
    // Ensure clicks on the checkbox also work
    if (evt.target === checkbox) {
      return;
    }
    if (checkbox) {
      checkbox.checked = !checkbox.checked;
    }
  }
}
