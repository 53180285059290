/**
 * AssemblyAI Speech Recognition
 * Handles audio recording and transcription using AssemblyAI API
 */
export default class AssemblyAISpeech {
  constructor() {
    this.mediaRecorder = null;
    this.stream = null;
    this.audioChunks = [];
    this.isRecording = false;
  }

  /**
   * Start recording audio from the microphone
   * @param {Function} onStatusChange - Callback for recording status changes
   * @returns {Promise<void>}
   */
  async startRecording(onStatusChange) {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(this.stream);
      this.audioChunks = [];

      this.mediaRecorder.addEventListener("dataavailable", (event) => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data);
        }
      });

      this.mediaRecorder.start();
      this.isRecording = true;
      if (onStatusChange) onStatusChange(true);
    } catch (error) {
      if (onStatusChange) onStatusChange(false, error.message);
    }
  }

  /**
   * Stop recording and process the audio
   * @param {Function} onTranscriptionComplete - Callback with transcription result
   * @param {Function} onStatusChange - Callback for recording status changes
   * @returns {Promise<string|null>}
   */
  async stopRecording(onTranscriptionComplete, onStatusChange) {
    if (!this.mediaRecorder || this.mediaRecorder.state === "inactive") {
      return null;
    }

    return new Promise((r) => {
      this.mediaRecorder.addEventListener("stop", async () => {
        try {
          if (onStatusChange) onStatusChange(false, "Processing audio...");

          const audioBlob = new Blob(this.audioChunks, { type: "audio/webm" });
          const transcription = await this.transcribeAudio(audioBlob);

          if (onTranscriptionComplete) {
            onTranscriptionComplete(transcription);
          }

          r(transcription);
        } catch (error) {
          if (onStatusChange) onStatusChange(false, `Error: ${error.message}`);
          r(null);
        } finally {
          this.isRecording = false;
          this.stopMediaTracks();
        }
      });

      this.mediaRecorder.stop();
    });
  }

  /**
   * Stop all media tracks to release the microphone
   */
  stopMediaTracks() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }
  }

  /**
   * Get CSRF token from cookies
   * @returns {string} CSRF token
   * @static
   */
  // eslint-disable-next-line class-methods-use-this
  getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let i = 0; i < cookieArray.length; i += 1) {
      const cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  }

  /**
   * Transcribe audio using AssemblyAI API
   * @param {Blob} audioBlob - The audio blob to transcribe
   * @returns {Promise<string>} - The transcription result
   */
  async transcribeAudio(audioBlob) {
    // First, upload the audio file via our backend proxy
    const formData = new FormData();
    formData.append("audio", audioBlob, "audio.wav"); // Add filename to help with MIME type detection
    formData.append("endpoint", "upload");

    const csrfToken = this.getCsrfToken();

    const uploadResponse = await fetch("/api/v2/speech-recognition/upload", {
      method: "POST",
      body: formData,
      credentials: "same-origin", // Include cookies for authentication
      headers: {
        "X-CSRFToken": csrfToken,
      },
    });

    if (!uploadResponse.ok) {
      throw new Error(`Upload failed with status: ${uploadResponse.status}`);
    }

    const uploadResult = await uploadResponse.json();
    const audioUrl = uploadResult.upload_url;

    // Then, submit the audio for transcription via our backend proxy
    const transcribeResponse = await fetch(
      "/api/v2/speech-recognition/transcript",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({
          endpoint: "transcript",
          data: {
            audio_url: audioUrl,
            language_code: "en_us",
          },
        }),
        credentials: "same-origin", // Include cookies for authentication
      },
    );

    if (!transcribeResponse.ok) {
      throw new Error(
        `Transcription request failed with status: ${transcribeResponse.status}`,
      );
    }

    const transcribeResult = await transcribeResponse.json();
    const transcriptId = transcribeResult.id;

    // Poll for the transcription result via our backend proxy
    let result = null;
    let polling = true;

    // eslint-disable-next-line no-await-in-loop
    while (polling) {
      // Wait 0.5 second between polls
      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
      await new Promise((r) => {
        setTimeout(() => {
          r();
        }, 500);
      });

      // eslint-disable-next-line no-await-in-loop
      const pollingResponse = await fetch(
        `/api/v2/speech-recognition/transcript/${transcriptId}`,
        {
          method: "GET",
          credentials: "same-origin", // Include cookies for authentication
          headers: {
            "X-CSRFToken": csrfToken,
          },
        },
      );

      if (!pollingResponse.ok) {
        throw new Error(
          `Polling failed with status: ${pollingResponse.status}`,
        );
      }

      // eslint-disable-next-line no-await-in-loop
      result = await pollingResponse.json();

      if (result.status === "completed") {
        polling = false;
      } else if (result.status === "error") {
        throw new Error(`Transcription error: ${result.error}`);
      }
    }

    return result.text || "";
  }
}
