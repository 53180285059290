import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";
import { useSpeechRecognition } from "../../behaviors";

export default class extends Controller {
  static targets = ["textQuestion", "sendButton", "micButton"];

  // eslint-disable-next-line class-methods-use-this
  connect() {
    // deselect all threads
    $(".thread-list .list-group-item").removeClass("active");

    if (this.hasMicButtonTarget) {
      // Initialize speech recognition
      useSpeechRecognition(this);
    }
  }

  generateFollowupEmail() {
    this.textQuestionTarget.value = "Write a follow-up email";
    this.sendButtonTarget.click();

    trackEventWithDataToAmplitudeAndGoogle(
      "Question",
      "chatbot",
      "Work Stream Chatbot - Question",
      {
        query: "Write a follow-up email",
      },
    );
  }

  generateAgenda() {
    this.textQuestionTarget.value = "Write an agenda for the next meeting";
    this.sendButtonTarget.click();

    trackEventWithDataToAmplitudeAndGoogle(
      "Question",
      "chatbot",
      "Work Stream Chatbot - Question",
      {
        query: "Write an agenda for the next meeting",
      },
    );
  }
}
